import React from "react"
import { graphql, StaticQuery } from 'gatsby';
import styled from "styled-components"
import Layout from "../components/Layout"
import theme from "../general/theme"
import Box from "../elements/Box"
import OpenIcon from '-!svg-react-loader!../images/icons/open.svg'

import {
  Trafalgar,
  BodyCopy,
} from '../typography';
const { white, gray8 } = theme.colours;


export default props => (
  <StaticQuery
  query={graphql`
    query AnnualReportsAndMeetingMinutes {
      allSanityMeetingMinutes(sort: {fields: collection___year, order: DESC}) {
        edges {
          node {
            collection {
              meeting_minutes_pdfs {
                asset {
                  url
                }
                pdf_title
                _key
              }
              year
              annual_reports_pdfs {
                _key
                asset {
                  url
                }
                pdf_title
              }
            }
          }
        }
      }
    }
  `}
  render={data => <AnnualReportsAndMeetingMinutes data={data} {...props} />}
  />
)

const Content = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8rem;
`;

const AnnualReportsAndMeetingMinutes = ({data}) => {
  const AllMeetingMinutes = data.allSanityMeetingMinutes.edges;

  function buildMeetingMinutesCard(x) {
    const id = x.node.id;
    // const year = x.node.collection.year;
    const annual_reports_pdfs = x.node.collection.annual_reports_pdfs
    const meeting_minutes_pdfs = x.node.collection.meeting_minutes_pdfs

    return (
      <Section key={id}>
        {/* <Trafalgar mb="4rem" color={gray8}>{year}</Trafalgar> */}
        <Trafalgar mb="4rem" color={gray8}>Annual Reports</Trafalgar>
        {annual_reports_pdfs.map(buildPdfCard)}
        <Trafalgar mt="8rem" mb="4rem" color={gray8}>Meeting Minutes</Trafalgar>
        {meeting_minutes_pdfs.map(buildPdfCard)}
      </Section>
    )
  }
  
  function buildPdfCard(x) {
    const title = x.pdf_title;
    const url = x.asset.url;
    const id = x.asset.id;

    return (
      <a href={url} target="_blank" rel="noopener noreferrer" key={id}>
        <Box py="4rem" px="2rem" background={white} borderRadius="8px" mb="2rem" display="flex" justifyContent="space-between">
          <BodyCopy>{title}</BodyCopy>
          <OpenIcon></OpenIcon>
        </Box>
      </a>
    )
  }
  
  return (
    <Layout>
      <Content>
        <>
          <Section>
            {AllMeetingMinutes.map(buildMeetingMinutesCard)}
          </Section>
        </>
      </Content>
    </Layout>
  );
}

